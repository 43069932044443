// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ui.label.basic.button.oV_ME.NGldo{background:#fff!important;box-shadow:none!important;white-space:nowrap!important}.ui.label.basic.button.oV_ME.NGldo i{opacity:.85!important}.ui.label.basic.button.oV_ME.NGldo:hover i{opacity:1!important}.oV_ME.NGldo.aNbQk{border-radius:0 .2143rem 0 .2857rem!important;margin:-1px!important;padding:.75em 1em!important;position:absolute;right:0;top:0}.oV_ME.NGldo._72FQs{align-items:center;display:flex}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `oV_ME`,
	"copy": `NGldo`,
	"corner": `aNbQk`,
	"icon": `_72FQs`
};
module.exports = ___CSS_LOADER_EXPORT___;
