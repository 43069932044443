import useStyles from 'isomorphic-style-loader/useStyles';
import {
  Modal as SemanticModal,
  type ModalProps as SemanticModalProps,
  ModalActions as SemanticModalActions,
  ModalContent as SemanticModalContent,
  ModalDescription as SemanticModalDescription,
  ModalDimmer as SemanticModalDimmer,
  ModalHeader as SemanticModalHeader,
} from 'semantic-ui-react';

import type { ModalData, ModalType } from '@models';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  hideModal,
  selectActiveModal,
  selectActiveModalProps,
  selectIsModalVisible,
  showModal,
} from '@redux/modals.slice';

import s from './Modal.scss';

export interface UseModalReturn<
  Type extends ModalType,
  Data extends ModalData<Type> = ModalData<Type>,
> {
  data: Data | undefined;
  isVisible: boolean;
  isOpen: boolean;
  open: (props: Data) => void;
  close: () => void;
}

/**
 * A hook to manage the state of a modal in the Redux store. Returns an object
 * containing the current state, data and methods to open and close the modal.
 */
export const useModal = <
  Type extends ModalType,
  Data extends ModalData<Type> = ModalData<Type>,
>(
  type: Type,
): UseModalReturn<Type, Data> => {
  const dispatch = useAppDispatch();
  const instance = useAppSelector(selectActiveModal(type));
  const data = useAppSelector(selectActiveModalProps<Type, Data>(type));
  const isVisible = useAppSelector(selectIsModalVisible(type));

  return {
    data,
    isVisible,
    isOpen: !!instance,
    open: (props: Data) => dispatch(showModal({ type, props })),
    close: () => dispatch(hideModal(type)),
  };
};

export interface ModalProps<T extends ModalType> extends SemanticModalProps {
  /**
   * A unique id for the modal instance. This should be unique across all modals
   * in the application, and will be used to manage the modal's state and data
   * in the Redux store.
   */
  id: T;
}

const Modal = <T extends ModalType>(props: ModalProps<T>) => {
  useStyles(s);

  const { id, children } = props;
  const { isVisible, isOpen, close } = useModal(id);

  return (
    <SemanticModal
      role="dialog"
      className={!isVisible ? s.hiddenModal : ''}
      style={{ visibility: isVisible ? 'visible' : 'hidden' }}
      open={isOpen}
      closeIcon={isVisible}
      dimmer={{ style: { background: 'transparent' } }}
      onClose={close}
      mountNode={process.env.BROWSER && document?.getElementById('rootDimmer')}
      {...props}
    >
      {children}
    </SemanticModal>
  );
};

export default Object.assign(Modal, {
  Actions: SemanticModalActions,
  Content: SemanticModalContent,
  Description: SemanticModalDescription,
  Dimmer: SemanticModalDimmer,
  Header: SemanticModalHeader,
});
