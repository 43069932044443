import {
  SET_IMAGE_ADD_ERROR,
  SET_IMAGE_ADD_PENDING,
  SET_IMAGE_ADD_SUCCESS,
  SET_IMAGE_REMOVE_ERROR,
  SET_IMAGE_REMOVE_PENDING,
  SET_IMAGE_REMOVE_SUCCESS,
  SET_IMAGES_REMOVE_ERROR,
  SET_IMAGES_REMOVE_PENDING,
  SET_IMAGES_REMOVE_SUCCESS,
  SET_IMAGES_ROUTE_DATA,
  SET_REPOSITORY_ADD_ERROR,
  SET_REPOSITORY_ADD_PENDING,
  SET_REPOSITORY_ADD_SUCCESS,
} from '../actionTypes';

export const defaultState = {
  isAddingImage: false,
  addImageError: false,
  isRemovingImage: false,
  removeImageError: false,
  isRemovingImages: false,
  removeImagesError: false,
  isAddingRepository: false,
  addRepositoryError: false,
  errorMsg: false,
  data: false,
  routeData: [],
  routeInfo: {},
};

export default (state = defaultState, action = {}) => {
  switch (action.type) {
    /**
     * *************************************************************************
     * IMAGE SELECTION, TOP-LEVEL - IMAGE DIALOG DATA REDUCERS
     */
    case 'modals/showModal': {
      if (action.payload.type === 'analyzeTag') {
        return {
          ...state,
          addImageError: false,
          errorMsg: false,
        };
      }

      if (action.payload.type === 'analyzeRepository') {
        return {
          ...state,
          addRepositoryError: false,
          errorMsg: false,
        };
      }

      return state;
    }

    /**
     * *************************************************************************
     * IMAGE SELECTION, TOP-LEVEL - FETCH IMAGE DATA REDUCERS
     */
    case SET_IMAGES_ROUTE_DATA:
      return {
        ...state,
        routeData: action.routeData,
        routeInfo: action.routeInfo,
      };

    /**
     * *************************************************************************
     * REPOSITORY DATA REDUCERS
     */
    case SET_REPOSITORY_ADD_PENDING:
      return {
        ...state,
        isAddingRepository: true,
        addRepositoryError: false,
        errorMsg: false,
      };
    case SET_REPOSITORY_ADD_SUCCESS:
      return {
        ...state,
        isAddingRepository: false,
        addRepositoryError: false,
        errorMsg: false,
      };
    case SET_REPOSITORY_ADD_ERROR:
      return {
        ...state,
        isAddingRepository: false,
        addRepositoryError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * IMAGE SELECTION, TOP-LEVEL - ADD IMAGE DATA REDUCERS
     */
    case SET_IMAGE_ADD_PENDING:
      return {
        ...state,
        isAddingImage: action.id || true,
      };
    case SET_IMAGE_ADD_SUCCESS:
      return {
        ...state,
        isAddingImage: false,
        addImageError: false,
        errorMsg: false,
      };
    case SET_IMAGE_ADD_ERROR:
      return {
        ...state,
        isAddingImage: false,
        addImageError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * IMAGE SELECTION, TOP-LEVEL - REMOVE DATA REDUCERS
     */
    case SET_IMAGE_REMOVE_PENDING:
      return {
        ...state,
        isRemovingImage: action.id || true,
      };
    case SET_IMAGE_REMOVE_SUCCESS:
      return {
        ...state,
        isRemovingImage: false,
        removeImageError: false,
        errorMsg: false,
      };
    case SET_IMAGE_REMOVE_ERROR:
      return {
        ...state,
        isRemovingImage: false,
        removeImageError: true,
        errorMsg: action.errorMsg,
      };

    /**
     * *************************************************************************
     * IMAGES SELECTION, TOP-LEVEL - REMOVE DATA REDUCERS
     */
    case SET_IMAGES_REMOVE_PENDING:
      return {
        ...state,
        isRemovingImages: true,
      };
    case SET_IMAGES_REMOVE_SUCCESS:
      return {
        ...state,
        isRemovingImages: false,
        removeImagesError: false,
        errorMsg: false,
        isRemoveImagesModalOpen: false,
      };
    case SET_IMAGES_REMOVE_ERROR:
      return {
        ...state,
        isRemovingImages: false,
        removeImagesError: true,
        errorMsg: action.errorMsg,
      };

    default:
      return state;
  }
};
