import classNames from 'classnames';
import useStyles from 'isomorphic-style-loader/useStyles';
import { Button, type ButtonProps } from 'semantic-ui-react';

import s from './CancelButton.scss';

export interface CancelButtonProps extends ButtonProps {
  dark?: boolean;
}

/**
 * A reusable 'Cancel' button to serve as a common modal action to close it. Any
 * Semantic UI Button prop can be passed in. Often, the disabled and onClick props
 * are the only ones needed. Passing `children` overrides the button label text,
 * which defaults to 'Cancel'.
 *
 * @param buttonProps.disabled Boolean on whether the button should be disabled
 * @param buttonProps.onClick Function to call on click of "Cancel" button
 *
 * @example
 * // Intended usage
 * <CancelButton
 *   disabled={isUpdating}
 *   onClick={() => dispatch(hideModal('editTemplate'))}
 * />
 */
const CancelButton = ({
  children = 'Cancel',
  className,
  dark = false,
  ...buttonProps
}: CancelButtonProps) => {
  useStyles(s);

  return (
    <Button
      className={classNames(
        dark || window?.App?.darkReader?.isEnabled() ? s.dark : s.light,
        className,
      )}
      {...buttonProps}
    >
      {children}
    </Button>
  );
};

export default CancelButton;
