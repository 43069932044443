import {
  createAsyncThunk,
  createSlice,
  type PayloadAction,
} from '@reduxjs/toolkit';
import _merge from 'lodash/merge';
import request from 'superagent';

import type {
  Application,
  ArtifactId,
  ArtifactList,
  AnchoreImageTagSummary,
} from '@models';
import {
  SET_IMAGES_FETCH_ERROR,
  SET_IMAGES_FETCH_PENDING,
  SET_IMAGES_FETCH_SUCCESS,
} from '@redux/actionTypes';
import type {
  GetApplicationsResponse,
  GetApplicationArtifactsResponse,
} from '@services';
import artifactTypes from '@shared/artifactTypes';

import type { AppDispatch } from './store';

export interface ApplicationsSelectedViewProps {
  application?: string;
  version?: string;
  applicationName?: string;
  versionName?: string;
}

export type ApplicationsDetailsViewProps = {
  [artifactType in ArtifactId]: {
    page: number;
    pageSize: number;
    filterStr: string;
  };
};

export interface ApplicationsViewProps {
  selected: ApplicationsSelectedViewProps;
  details: ApplicationsDetailsViewProps;
}

export type ApplicationsViewPropsType = keyof ApplicationsViewProps;
export interface ApplicationsState {
  applicationsData?: Application[];
  isFetchingApplications: boolean;
  fetchApplicationsError: boolean;
  images?: AnchoreImageTagSummary[];
  isFetchingImageList: boolean;
  fetchImageListError: boolean;
  artifacts?: ArtifactList;
  isFetchingArtifacts: boolean;
  fetchArtifactsError: boolean;
  error?: any;
  viewProps: ApplicationsViewProps;
}

const defaultViewPropsDetails = {
  page: 0,
  pageSize: 10,
  filterStr: '',
};

export const initialState = {
  isFetchingApplications: false,
  fetchApplicationsError: false,
  isFetchingImageList: false,
  fetchImageListError: false,
  isFetchingArtifacts: false,
  fetchArtifactsError: false,
  viewProps: {
    selected: {
      application: '',
      version: '',
      applicationName: '',
      versionName: '',
    },
    details: {
      ...artifactTypes.reduce(
        (acc, type) => ({ ...acc, [type.id]: defaultViewPropsDetails }),
        {},
      ),
    },
  },
} as ApplicationsState;

// Action creators
export interface ViewPropsUpdateActionPayload {
  view: ApplicationsViewPropsType;
  props: Partial<ApplicationsViewProps[ApplicationsViewPropsType]>;
}

const callIfHealthy = <T>(thunkAPI: any, callback: () => T) => {
  const currState = thunkAPI.getState();
  const isHealthy = currState.app?.healthCheck?.isEngineHealthy;

  if (isHealthy) {
    return callback();
  }
  throw new Error('Services not healthy');
};

export const fetchApplications = createAsyncThunk<
  GetApplicationsResponse,
  void,
  {
    state: ApplicationsState;
    dispatch: AppDispatch;
    rejectValue: GetApplicationsResponse;
  }
>('applications/fetch', async (_, thunkAPI) =>
  callIfHealthy(thunkAPI, async () => {
    try {
      const resp = await request
        .get('/service/applications')
        .set('Content-Type', 'application/json');
      return resp.body as GetApplicationsResponse;
    } catch (e: any) {
      if (!e.response?.body?.data) throw e;
      return thunkAPI.rejectWithValue(e.response.body.data);
    }
  }),
);

export const fetchApplicationArtifacts = createAsyncThunk<
  GetApplicationArtifactsResponse,
  { artifactType: ArtifactId; applicationId: string; versionId: string },
  {
    state: ApplicationsState;
    dispatch: AppDispatch;
    rejectValue: GetApplicationArtifactsResponse;
  }
>('applications/fetchArtifacts', async (params, thunkAPI) =>
  callIfHealthy(thunkAPI, async () => {
    try {
      const resp = await request
        .get(
          `/service/applications/${params.applicationId}/versions/${params.versionId}/artifacts?type=${params.artifactType}`,
        )
        .set('Content-Type', 'application/json');
      return resp.body as GetApplicationArtifactsResponse;
    } catch (e: any) {
      if (!e.response?.body?.data) throw e;
      return thunkAPI.rejectWithValue(e.response.body.data);
    }
  }),
);

const sortApplicationVersions = (application: Application) => ({
  ...application,
  application_versions: application.application_versions?.sort(
    (a, b) => Date.parse(b.created_at!) - Date.parse(a.created_at!),
  ),
});

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    setApplicationsViewProps: (
      state,
      action: PayloadAction<ViewPropsUpdateActionPayload>,
    ) => {
      const { view } = action.payload;
      if (state.viewProps[view]) {
        state.viewProps[view] = _merge(
          state.viewProps[view],
          action.payload.props,
        ) as any;
      }
    },
  },
  extraReducers: builder => {
    builder
      // Fetching applications
      .addCase(fetchApplications.pending, state => {
        state.isFetchingApplications = true;
      })
      .addCase(fetchApplications.fulfilled, (state, action) => ({
        ...state,
        isFetchingApplications: false,
        fetchApplicationsError: false,
        applicationsData: action.payload.data.map(app =>
          sortApplicationVersions(app),
        ),
      }))
      .addCase(fetchApplications.rejected, (state, action) => ({
        ...state,
        isFetchingApplications: false,
        fetchApplicationsError: true,
        error: action.payload || action.error,
      }))

      // Fetching Application Artifacts
      .addCase(fetchApplicationArtifacts.pending, state => {
        state.isFetchingArtifacts = true;
        state.artifacts = undefined;
      })
      .addCase(fetchApplicationArtifacts.fulfilled, (state, action) => ({
        ...state,
        artifacts: action.payload.data,
        isFetchingArtifacts: false,
        fetchArtifactsError: false,
      }))
      .addCase(fetchApplicationArtifacts.rejected, (state, action) => ({
        ...state,
        artifacts: undefined,
        isFetchingArtifacts: false,
        fetchArtifactsError: true,
        error: action.payload || action.error,
      }))

      // Fetching images
      .addCase(SET_IMAGES_FETCH_PENDING, state => ({
        ...state,
        isFetchingImageList: true,
        images: undefined,
      }))
      .addCase(SET_IMAGES_FETCH_SUCCESS, (state, action: any) => ({
        ...state,
        isFetchingImageList: false,
        fetchImageListError: false,
        error: undefined,
        images: action.resp.data,
      }))
      .addCase(SET_IMAGES_FETCH_ERROR, (state, action: any) => ({
        ...state,
        isFetchingImageList: false,
        fetchImageListError: true,
        error: action.errorMsg,
        images: undefined,
      }));
  },
});

export const { setApplicationsViewProps } = applicationsSlice.actions;

export default applicationsSlice.reducer;
