// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.M6hzc{background-color:#f9fafb;border-radius:.2857rem!important;box-shadow:inset 0 0 0 1px #dededf;display:flex;margin:.125rem 0}.M6hzc .AqeI2{border-radius:0;flex:1 1;margin:0;padding:0}.M6hzc .AqeI2 label{font-weight:700!important;text-shadow:1px 1px #fff}._j390{display:flex;margin:0!important;padding:0!important}._j390:first-child{margin-bottom:.5rem!important}._j390:last-child{margin-top:.5rem!important}.ui.changePass.form .field>label{font-weight:500}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `M6hzc`,
	"grid": `AqeI2`,
	"ruleItem": `_j390`
};
module.exports = ___CSS_LOADER_EXPORT___;
