// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root .KEGH4{background-color:unset}:root .KEGH4 .XUWF3,:root .KEGH4 .BalSK,:root .KEGH4 .py7mv{background-color:#1c1e1f}:root .KEGH4 .XUWF3{border-top-color:#93897926}:root .KEGH4 .kIM5Z,:root .KEGH4 .pE8aP{color:#ffffffde}:root .KEGH4 .py7mv{color:#ffffffd9;font-size:26.4px;font-size:1.65rem}:root .KEGH4 .pE8aP{font-size:19.2px;font-size:1.2rem;margin-bottom:1.5rem}:root .KEGH4 .kIM5Z{font-size:17.6px;font-size:1.1rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"confirmationModal": `KEGH4`,
	"actions": `XUWF3`,
	"content": `BalSK`,
	"title": `py7mv`,
	"text": `kIM5Z`,
	"textHeader": `pE8aP`
};
module.exports = ___CSS_LOADER_EXPORT___;
