import metadata from '@/../package.json';

export default (/* props = {} */) => ({
  '/*': [
    {
      id: 'main',
      state: [],
      steps: [
        // Start
        {
          styles: {
            options: {
              width: '65rem',
            },
          },
          content: (
            <div style={{ overflow: 'auto', maxHeight: '75vh' }}>
              <h2 style={{ marginBottom: '2rem' }}>
                <span>
                  <span>Welcome to Anchore Enterprise Client</span>
                  &nbsp;
                  <span
                    className="animate__animated animate__rotateIn"
                    style={{
                      display: 'inline-block',
                      color: '#FF880B',
                    }}
                  >
                    {metadata.version.split('.').slice(0, 2).join('.')}
                  </span>
                </span>
                <span
                  style={{
                    background: 'url("/img/robot_box.svg")',
                    width: '9rem',
                    height: '8rem',
                    backgroundSize: 'contain',
                    position: 'absolute',
                    backgroundRepeat: 'no-repeat',
                    marginLeft: '2rem',
                    top: '-3.25rem',
                  }}
                />
              </h2>
              <p>
                We&#39;ve added new features, addressed known issues, and
                applied a generous helping of latest-version goodness in the
                form of security updates and performance improvements.
              </p>
              <p>
                We hope you enjoy using the application and would love to hear
                your feedback. Please contact us via&nbsp;
                <a
                  target="_blank"
                  href="https://support.anchore.com"
                  rel="noopener noreferrer"
                >
                  support.anchore.com
                </a>
                &nbsp;for any issues or questions.
              </p>
              <p>
                And to those of you who are new to Anchore—<em>welcome</em>!
              </p>
              {/* <p>
                Go on, click <strong>Next</strong>... You&#39;ll be glad you did
                🙂
              </p> */}
            </div>
          ),
          placement: 'center',
          target: 'body',
          intro: true,
        },

        // End
        // {
        //   styles: {
        //     options: {
        //       width: '52rem',
        //     },
        //   },
        //   content: (
        //     <div style={{ overflow: 'auto', maxHeight: '75vh' }}>
        //       <h2 style={{ marginBottom: '2rem' }}>
        //         <em>&hellip;and More!</em>
        //       </h2>
        //       <p>
        //         Refer to the details provided in the&nbsp;
        //         <strong>What&#39;s New in This Release</strong> dropdown to see
        //         what else we&#39;ve been up to.
        //       </p>
        //       <p>
        //         We hope you enjoy using the application and would love to hear
        //         your feedback. Please contact us via&nbsp;
        //         <a
        //           target="_blank"
        //           href="https://support.anchore.com"
        //           rel="noopener noreferrer"
        //         >
        //           support.anchore.com
        //         </a>
        //         &nbsp;for any issues or questions.
        //       </p>
        //       <p>
        //         And to those of you who are new to Anchore—<em>welcome</em>!
        //       </p>
        //       <Image
        //         centered
        //         src="/img/robot_saucer2.svg"
        //         style={{ width: '30rem' }}
        //       />
        //     </div>
        //   ),
        //   placement: 'center',
        //   target: 'body',
        //   intro: true,
        // },
      ],
    },
  ],
});
