import classNames from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import _debounce from 'lodash/debounce';
import _startCase from 'lodash/startCase';
import { matchSorter } from 'match-sorter';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Icon,
  Loader,
  Message,
  Popup,
} from 'semantic-ui-react';

import {
  listApiKeys,
  setAccountsViewProps,
  updateApiKey,
} from '@redux/accounts.slice';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import {
  hideModal,
  selectActiveModalProps,
  showModal,
} from '@redux/modals.slice';
import DeleteModal from '@shared/components/DeleteModal';
import Err from '@shared/components/Err';
import Modal from '@shared/components/Modal';
import ReactTable from '@shared/components/ReactTable';
import TableInfo from '@shared/components/Table/TableInfo';

import NewApiKeyModal from './NewApiKeyModal';
// eslint-disable-next-line postcss-modules/no-unused-class
import s from './ApiKeysModal.scss';

const USER_TIMEZONE = moment.tz.guess();

const statusIcons = {
  active: <Icon name="check" color="green" />,
  revoked: <Icon name="times" color="red" />,
  expired: <Icon name="clock outline" color="orange" />,
};

export interface ApiKeysModalProps {
  permissions: {
    createApiKey?: boolean;
    updateApiKey?: boolean;
  };
}

const ApiKeysModal = (props: ApiKeysModalProps) => {
  const { permissions } = props;
  const dispatch = useAppDispatch();
  const modalProps = useAppSelector(selectActiveModalProps('apiKeys'));
  const { data, isLoading, error, isUpdating } = useAppSelector(
    state => state.accounts.apiKeys,
  );
  const { apiKeysModal: viewProps } = useAppSelector(
    state => state.accounts.viewProps,
  );
  const { account: currentAccount } = useAppSelector(state => state.auth);
  const { username: currentUserName, accountname: currentAccountName } =
    currentAccount;

  const getKeyList = useCallback(() => {
    if (modalProps) {
      dispatch(
        listApiKeys({
          account: modalProps.account,
          user: modalProps.userName,
        }),
      );
    }
  }, [modalProps]);

  // Load the list of API keys on display of the modal
  useEffect(() => {
    if (modalProps) {
      getKeyList();
    }
  }, [modalProps]);

  // Should the table display keys that are currently active? (default true)
  const [showActiveOnly, setShowActiveOnly] = useState(true);

  // Convert data to a list, and filter for only active tokens, if applicable
  const apiKeys = useMemo(
    () =>
      data
        ? Object.keys(data)
            .map(key => data[key])
            .filter(apiKey => !showActiveOnly || apiKey.status === 'active')
        : [],
    [data, showActiveOnly],
  );

  if (!modalProps) return null;
  const { account, userName } = modalProps;

  // This is the little arrow used to separate breadcrumb items
  const caret = (
    <Icon color="teal" size="small" name="triangle right" className={s.caret} />
  );

  // Are we displaying keys for the currently logged in user or for some other account/user?
  const selfApiKeys =
    currentAccountName === account && currentUserName === userName;
  const canCreateNewKey = selfApiKeys || permissions.createApiKey;
  const canRevokeKey = selfApiKeys || permissions.updateApiKey;

  return (
    <>
      <Modal id="apiKeys" size="large">
        <Modal.Header role="heading" aria-level={1}>
          <Icon.Group>
            <Icon color="teal" name="key" />
          </Icon.Group>
          &nbsp;API Keys{caret}
          {account}
          {caret}
          {userName}
        </Modal.Header>
        <Modal.Content scrolling>
          {error ? (
            <Err
              noBorder
              showRetryButton
              onRetryButtonClick={getKeyList}
              isRetrying={isLoading}
              imageSize="medium"
              error={error}
              className={s.error}
            />
          ) : isLoading ? (
            <Loader size="huge" active inline="centered" className={s.loader}>
              Loading Api Keys...
            </Loader>
          ) : (
            <>
              <div className={s.headerRow}>
                <span className={s.headerMessage}>
                  {selfApiKeys ? (
                    <span>Your API keys are shown below.</span>
                  ) : (
                    <span>
                      The API keys for user <strong>{userName}</strong> are
                      shown below.
                    </span>
                  )}
                </span>
                <div className={s.headerButtons}>
                  <Checkbox
                    toggle
                    label="Show only active API keys"
                    checked={showActiveOnly}
                    onChange={() => setShowActiveOnly(!showActiveOnly)}
                    className={classNames(
                      s.checkbox,
                      showActiveOnly ? s.checked : null,
                    )}
                  />
                  <Button
                    basic
                    icon
                    color="blue"
                    labelPosition="right"
                    size="small"
                    disabled={!canCreateNewKey}
                    onClick={() =>
                      dispatch(
                        showModal({
                          type: 'newApiKey',
                          props: {
                            account,
                            userName,
                          },
                        }),
                      )
                    }
                  >
                    <Icon name="plus" />
                    Create New API Key
                  </Button>
                </div>
              </div>
              <ReactTable
                resizable
                data={apiKeys}
                defaultSorted={[
                  {
                    id: 'createdAt',
                    desc: true,
                  },
                ]}
                filterStr={viewProps.filterStr}
                filterMethod={(filter, rows) =>
                  matchSorter(rows, filter.value, {
                    keys: ['name', 'description'],
                    threshold: matchSorter.rankings.CONTAINS,
                  })
                }
                columns={[
                  {
                    Header: 'Name',
                    id: 'name',
                    width: 100,
                    accessor: 'name',
                    Cell: row => {
                      const { value } = row;
                      return (
                        <Popup
                          hoverable
                          flowing
                          position="top center"
                          mouseEnterDelay={500}
                          trigger={
                            <span className={s.cursorArrow}>{value}</span>
                          }
                          content={value}
                        />
                      );
                    },
                  },
                  {
                    Header: 'Created',
                    id: 'createdAt',
                    width: 75,
                    accessor: 'created_at',
                    Cell: row => {
                      const { value } = row;
                      const createdAt = moment.utc(value).tz(USER_TIMEZONE);
                      const createdAtFromNow = createdAt.fromNow();

                      return (
                        <Popup
                          position="top center"
                          trigger={
                            <span className={s.cursorArrow}>
                              {createdAtFromNow.charAt(0).toUpperCase() +
                                createdAtFromNow.slice(1)}
                            </span>
                          }
                          content={createdAt.format(
                            'ddd, DD MMM YYYY [at] HH:mm:ss z',
                          )}
                        />
                      );
                    },
                  },
                  {
                    Header: 'Expires',
                    id: 'expiresAt',
                    width: 75,
                    accessor: 'expires_at',
                    Cell: row => {
                      const { value } = row;
                      const expiresAt = moment.utc(value).tz(USER_TIMEZONE);
                      const expiresAtFromNow = expiresAt.fromNow();
                      return (
                        <Popup
                          position="top center"
                          trigger={
                            <span className={s.cursorArrow}>
                              {expiresAtFromNow.charAt(0).toUpperCase() +
                                expiresAtFromNow.slice(1)}
                            </span>
                          }
                          content={expiresAt.format(
                            'ddd, DD MMM YYYY [at] HH:mm:ss z',
                          )}
                        />
                      );
                    },
                  },
                  {
                    Header: 'Description',
                    id: 'description',
                    accessor: 'description',
                    width: 150,
                    Cell: row => {
                      const { value } = row;
                      return (
                        <Popup
                          hoverable
                          flowing
                          position="top center"
                          mouseEnterDelay={500}
                          trigger={
                            <span className={s.cursorArrow}>
                              {value || 'None'}
                            </span>
                          }
                          content={value || 'No description was provided'}
                        />
                      );
                    },
                  },
                  {
                    Header: 'Status',
                    id: 'status',
                    headerClassName: s.statusColumnHeader,
                    className: s.statusColumn,
                    accessor: 'status',
                    width: 50,
                    Cell: row => {
                      const { value } = row;
                      return (
                        <div className={s.statusCell}>
                          {statusIcons[value]}
                          <span>{_startCase(value)}</span>
                        </div>
                      );
                    },
                  },
                  {
                    id: 'actions',
                    Header: 'Actions',
                    headerClassName: s.actionColumnHeader,
                    fixedWidth: 12,
                    className: s.actionColumn,
                    sortable: false,
                    Cell: row => {
                      const { original } = row;

                      return (
                        <Button
                          basic
                          aria-label="Revoke API key"
                          disabled={
                            original.status !== 'active' || !canRevokeKey
                          }
                          size="small"
                          color="red"
                          compact
                          onClick={() => {
                            dispatch(
                              showModal({
                                type: 'revokeApiKey',
                                props: {
                                  account,
                                  userName,
                                  keyName: original.name,
                                },
                              }),
                            );
                          }}
                        >
                          <Icon name="times" />
                          <span>Revoke</span>
                        </Button>
                      );
                    },
                  },
                ]}
                page={viewProps.page}
                pageSize={viewProps.pageSize}
                showPagination={!!apiKeys?.length}
                className="-striped"
                NoDataComponent={() => (
                  <div style={{ padding: '1rem', textAlign: 'center' }}>
                    {viewProps.filterStr && data?.length ? (
                      <Message>
                        <Icon name="exclamation circle" />
                        No results returned for this search filter.
                      </Message>
                    ) : (
                      <Message>
                        <Icon name="info circle" color="orange" />
                        No API keys have been created. You can create a new API
                        key by clicking the Create New API Key button above.
                      </Message>
                    )}
                  </div>
                )}
                getPaginationProps={() => ({
                  items: 'API Keys',
                  filterStr: viewProps.filterStr,
                })}
                onPageChange={(pageIndex: number) => {
                  dispatch(
                    setAccountsViewProps({
                      apiKeysModal: {
                        page: pageIndex,
                        filterStr: viewProps.filterStr,
                      },
                    }),
                  );
                }}
                onPageSizeChange={size => {
                  // Normalize the page to display
                  const currentRow = viewProps.pageSize * viewProps.page;
                  const newPage = Math.floor(currentRow / size);
                  dispatch(
                    setAccountsViewProps({
                      apiKeysModal: {
                        page: newPage,
                        pageSize: size,
                      },
                    }),
                  );
                }}
                onFilteredChange={_debounce(
                  (column, value) =>
                    dispatch(
                      setAccountsViewProps({
                        apiKeysModal: {
                          filterStr: value,
                          page: 0,
                        },
                      }),
                    ),
                  300,
                )}
              >
                {(state, func, tableObj) => (
                  <div>
                    {func()}
                    <TableInfo
                      state={state}
                      item="API Key"
                      tableObj={tableObj}
                    />
                  </div>
                )}
              </ReactTable>
            </>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="blue"
            onClick={() => {
              dispatch(hideModal('apiKeys'));
            }}
          >
            <Icon name="times" /> Close
          </Button>
        </Modal.Actions>
      </Modal>
      <DeleteModal
        id="revokeApiKey"
        item="API key"
        actionName="revoke"
        text="Once you revoke this API key, it can no longer be used for authentication, nor can it be reactivated."
        onConfirm={params =>
          dispatch(
            updateApiKey({
              account: params.account,
              name: params.keyName,
              user: params.userName,
              body: { status: 'revoked' },
            }),
          )
        }
        isExecuting={isUpdating}
        error={error}
      />
      <NewApiKeyModal />
    </>
  );
};

export default withStyles(s)(ApiKeysModal);
