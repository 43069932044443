import { useMemo } from 'react';
import type { OverrideProperties } from 'type-fest';

import type { ModalData, ModalType } from '@models';
import type { ConfirmationModalProps } from '@shared/components/ConfirmationModal';
import ConfirmationModal, {
  getSuccessfulActionName,
} from '@shared/components/ConfirmationModal';
import { useModal } from '@shared/components/Modal';

/**
 * In general, delete modals only need to accept an 'id' and 'name' prop to
 * display the correct text. Excess keys are allowed to be passed in as various
 * actions may need additional info.
 */
export type DeleteModalData<T extends ModalType> = {
  id: string;
  name?: string;
} & ModalData<T>;

export type DeleteModalProps<T extends ModalType> = OverrideProperties<
  ConfirmationModalProps<T, DeleteModalData<T>>,
  { actionName?: Lowercase<string> }
>;

/**
 * A reusable modal for deletion of an item.
 *
 * @implements {ConfirmationModal}
 */
const DeleteModal = <T extends ModalType>({
  id,
  item = 'item',
  actionIcon = 'trash',
  actionName = 'delete',
  color = 'red',
  text,
  textHeader,
  successMessage,
  ...props
}: DeleteModalProps<T>) => {
  const { data } = useModal<T, DeleteModalData<T>>(id);

  const extendedProps = useMemo(
    () => ({
      text:
        text || `Once you ${actionName} it, this ${item} can't be recovered.`,
      textHeader:
        textHeader ||
        `Are you sure you want to ${actionName} ${
          data?.name ? `the ${item} "${data.name}"` : `this ${item}`
        }?`,
      successMessage:
        successMessage ||
        `The ${item}${
          data?.name ? ` "${data.name}"` : ''
        } has been successfully ${getSuccessfulActionName(actionName)}.`,
    }),
    [data, text, textHeader, successMessage],
  );

  return (
    <ConfirmationModal
      color={color}
      id={id}
      item={item}
      actionIcon={actionIcon}
      actionName={actionName}
      {...props}
      {...extendedProps}
    />
  );
};

export default DeleteModal;
