import { createSlice } from '@reduxjs/toolkit';

import type { GetUsageResponse, StatusEntity, SystemUsageData } from '@models';
import { nullStatusEntity } from '@models';

import {
  createEntityAction,
  createStatusEntityReducers,
  getEntityActions,
} from './common';

const entities = {
  fetchSystemUsage: createEntityAction<void, GetUsageResponse>({
    stateKey: 'usage',
    servicePath: () => '/service/usage',
  }),
};

export const { fetchSystemUsage } = getEntityActions(entities);

export interface StatisticsState {
  usage: StatusEntity<SystemUsageData>;
}

export const initialState: StatisticsState = {
  usage: nullStatusEntity,
};

const statisticsSlice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: builder => {
    createStatusEntityReducers(entities, builder);
  },
});

export default statisticsSlice.reducer;
