import type { PayloadAction } from '@reduxjs/toolkit';
import { createSelector, createSlice } from '@reduxjs/toolkit';

import type { ModalInstance, ModalType, ModalData } from '@models';

import type { RootState } from './store';

export type ModalsState = Array<ModalInstance>;

export const initialState: ModalsState = [];

const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showModal: <T extends ModalType = ModalType>(
      state,
      action: PayloadAction<ModalInstance<T>>,
    ) => {
      state.push(action.payload);
    },
    hideModal: (state, action: PayloadAction<ModalType>) => {
      if (state.at(-1)?.type === action.payload) {
        state.pop();
      } else {
        console.error(
          `Cannot hide modal ${action.payload}: not currently active`,
        );
      }
    },
  },
  extraReducers: builder => {
    builder
      // --- Log Out -----------------------------------------------------------
      .addCase('SET_LOGOUT_SUCCESS', () => []);
  },
});

export const selectActiveModal = (modalType: ModalType) => (state: RootState) =>
  state.modals.find(modal => modal.type === modalType);

export const selectActiveModalProps =
  <Type extends ModalType, Data extends ModalData<Type> = ModalData<Type>>(
    modalType: Type,
  ) =>
  (state: RootState) =>
    state.modals.find(modal => modal.type === modalType)?.props as
      | Data
      | undefined;

export const selectIsModalVisible =
  (modalType: ModalType) => (state: RootState) =>
    state.modals.at(-1)?.type === modalType;

export const selectIsAnyModalActive = createSelector(
  (state: RootState) => state.modals,
  activeModals => activeModals.length > 0,
);

export const { hideModal, showModal } = modalsSlice.actions;

export default modalsSlice.reducer;
